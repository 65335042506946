import { PropsPage } from "shared/types";

export * from "./routes";

//! API endpoints are here !//
export * from "./endpoints";

//! Styling constants are here !//
export * from "./styling";

export const PUBLIC_API_URL = "https://api-dev.danametrics.ai/";
// export const PUBLIC_API_URL = "https://api.danametrics.ai/";

// export const SUPPORT_ADMIN_URL = "https://forms.gle/MVj8tjTMDpXv4UJg8";
// export const SUPPORT_CLIENT_URL = "https://forms.gle/CyMrGsjiceV626SU7";

export const SUPPORT_ADMIN_URL = "https://forms.gle/NtGs8YmzcqyZSt32A";
export const SUPPORT_CLIENT_URL = SUPPORT_ADMIN_URL;

export const MEDIAPLAN_SAMPLE_RELATIVE_PATH =
  "/danametrics_mediaplan_sample.xlsx";

export const routes: PropsPage[] = [
  { url: "/", title: "Home" },
  { url: "/users", title: "Users" },
  { url: "/table", title: "Table" },
];

export enum adsSystems {
  yandex = "YANDEX",
  vk = "VK",
  fb = "META",
  google = "GOOGLE",
  tiktok = "TIKTOK",
}

export const ADS_SYSTEMS_READABLE = {
  [adsSystems.fb]: "Meta",
  [adsSystems.vk]: "Vkontakte",
  [adsSystems.yandex]: "Yandex",
  [adsSystems.tiktok]: "TikTok",
  [adsSystems.google]: "Google",
};

export const currencies = ["KZT", "USD", "RUB"];
export const defaultFallbackCurrency = "KZT";
