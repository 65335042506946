import { CommonLayout, Header } from "shared/ui/layout";
import { Footer } from "widgets/layout";

export const AppDescription = () => (
  <div
    className="flex flex-col justify-between "
    style={{
      minHeight: "100vh",
    }}
  >
    <div className="flex-grow-0">
      <Header startPage="/" />
    </div>
    <CommonLayout title="">
      <div className=" flex-grow p-4 mx-auto">
        <div>
          Danametrics is a Digital Dashboars - tool to automate the work of a
          digital specialist. It consists of ready-made tools and algorithms for
          data collection, processing and loading, with the help of which
          digital specialist can configure the integration between the
          advertising cabinets and the database.
        </div>

        <div>
          Danametrics allows not to overload digital specialist with manual work
          related to the collection of statistics from advertising cabinets from
          the development of their business.
        </div>

        <div>Main features:</div>

        <ul>
          <li> Direct integration with advertising systems via API</li>
          <li>
            Automation of transfer Digital metrics (media and cost/budget
            metrics) from Google Adwords, Meta, TikTok and other advertising
            cabinets to the database
          </li>
          <li>
            Display of uploaded data in the form of dashboards - data
            visualization in the form of tables and graphs
          </li>
          <li> Uploading of reports in a convenient format: csv / pdf</li>
        </ul>
      </div>
    </CommonLayout>
    <div className="flex-grow-0">
      <Footer />
    </div>
  </div>
);
