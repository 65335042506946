import { CommonLayout, Header } from "shared/ui/layout";
import { Footer } from "widgets/layout";

export const PrivacyPolicy = () => (
  <div
    className="flex flex-col justify-between "
    style={{
      minHeight: "100vh",
    }}
  >
    <div className="flex-grow-0">
      <Header startPage="/" />
    </div>
    <CommonLayout title="">
      <div
        className="flex-grow p-4 mx-auto"
        dangerouslySetInnerHTML={{
          __html: `


<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Roboto;
	panose-1:2 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 42.5pt 2.0cm 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>


<div class=WordSection1>

<p class=MsoNoSpacing><b><span lang=RU>Политика конфиденциальности и политика в
отношении файлов Cookie</span></b></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p class=MsoNoSpacing><span lang=RU>Последнее обновление 20.05.2024 год</span></p>

<p class=MsoNoSpacing><b><span lang=RU>Введение</span></b></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p class=MsoNoSpacing><span lang=RU>Товарищество с ограниченной
ответственностью «<a name="_Hlk117855182">ADV Tech</a>» в лице И.О.Генерального
директора Кагарова А.А. действующего на основании Устава
(&quot;Администратор&quot;, &quot;мы,&quot; &quot;наш&quot;) предлагает широкий
спектр инструментов для повышения производительности, совместной работы и
организации, доступных онлайн, в том числе через веб-сайты, включая, но не
ограничиваясь, <span style=''>https://</span>app.danametrics.ai&nbsp;(«Веб-сайт»),
Danametrics, мобильное приложение (совместно именуемые «Сервис») и
предоставляемые нами услуги, а также любые другие приложения или услуги,
которые мы можем предложить (совместно именуемые «Услуги»). Политика
конфиденциальности и Политика в отношении файлов Cookie именуются далее
Политика.<br>
<br>
           Когда мы говорим «личные данные», мы имеем в виду информацию о вас,
такую как ваше имя, адрес электронной почты, физический адрес, информация об
оплате, запросы поддержки и так далее. Если вас не могут идентифицировать
(например, когда личные данные были анонимны и сгруппированы), эта Политика не
применяется.<br>
<br>
<br>
</span></p>

<p class=MsoNoSpacing><b><span lang=RU>1. Какие ваши данные мы собираем и как? </span></b></p>

<p class=MsoNoSpacing><span lang=RU>Когда вы посещаете или используете
некоторые части наших Сервисов и Веб-сайтов, мы можем попросить вас
предоставить нам некоторые личные данные. Например, мы запрашиваем ваши
контактные данные при регистрации, посещении Сервисов и посещении Веб-сайтов, использовании
Сервисов и Веб-сайтов, оплате Услуг, для рассылки, при получении запроса на
техническую поддержку, когда вы хотите связаться  с нами через форму обратной
связи. Если вы не хотите предоставлять нам личные данные, вам не нужно этого
делать, но это будет означать, что вы не сможете использовать наши  Сервисы и
Веб-сайты.<br>
<br>
Большая часть информации, которую мы собираем, мы собираем непосредственно от
вас. Иногда мы можем собирать личные данные о вас из других источников, таких
как общедоступные материалы или доверенные третьи стороны, такие как наши
партнеры по маркетингу и исследованиям. Мы используем эту информацию для
дополнения уже имеющихся у вас персональных данных, чтобы лучше информировать,
персонализировать и улучшать наши услуги, а также для проверки предоставленных
вами персональных данных.<br>
<br>
Некоторая информация о вас собирается автоматически, когда вы посещаете наши
Сервисы и Веб-сайты, например, ваш IP-адрес и данные браузера. Ваш IP-адрес
собирается для обеспечения безопасности и для того, чтобы примерно узнать, где
вы находитесь в мире, чтобы персонализировать свой опыт. Информация о вашем
браузере и операционной системе хранится, чтобы помочь вам в случае
возникновения проблем с использованием наших услуг. Когда вы просматриваете
наши веб-сайты, мы собираем информацию, в том числе о том, какие страницы вы
просматривали и по каким ссылкам переходили. Эта информация важна, поскольку
она помогает нам улучшать предоставляемые вам услуги путем мониторинга
производительности и того, что необходимо улучшить.<br>
<br>
Часть этой информации собирается с использованием файлов cookie и аналогичных
технологий отслеживания, которые подробно описаны в разделе Cookie ниже.</span></p>

<p class=MsoNoSpacing><span lang=RU>Тип информации, которую мы собираем, будет
зависеть от вашего взаимодействия с Сервисами и Веб-сайтами. Это может включать
в себя:</span></p>

<p class=MsoNoSpacing><span lang=RU>Контактная информация и информация для
входа.&nbsp;Имя, должность, адрес электронной почты, физический адрес, номер
телефона и аналогичные контактные данные, имя пользователя и пароли.<br>
<br>
Платежная информация.&nbsp;Номер кредитной карты, банковская информация и
платежный адрес.<br>
<br>
Демографическая информация.&nbsp;Занятость, род занятий, регион, семейное
положение. В некоторых случаях Сервисы и Веб-сайты могут собирать другую
демографическую информацию, такую как пол, раса и возраст.<br>
<br>
Данные об использовании и географические данные.&nbsp;Сервисы и Веб-сайты
собирают личную информацию, связанную с использованием вами наших продуктов,
услуг и веб-сайтов. Мы анализируем эти данные об использовании, чтобы мы могли
улучшить наши продукты и ваш опыт работы с нами. В некоторых случаях Сервисы и
Веб-сайты позволяют вам использовать инструменты администрирования, чтобы
отказаться от сбора некоторых данных об использовании.<br>
<br>
Данные об использовании могут включать информацию об операционной системе
вашего компьютера или мобильного устройства, типе браузера; о типе вашего
устройства; сведения о том, как вы используете наши продукты (включая запросы
на родном языке); можем просматривать адрес вашего интернет-протокола (IP) и
географические районы, полученные из вашего IP-адреса; данные сетевого подключения;
информацию о cookie-файлах Сервисов и Веб-сайтов; информацию в файлах;
метаданные; журналы с отметкой времени относительно времени доступа и
продолжительности посещений; веб-сайты, которые вы посетили до посещения
Сервисов и Веб-сайтов; и другие данные об использовании, относящиеся к вашей<br>
деятельности на наших Сервисах и Веб-сайтах, включая запрашиваемые вами
страницы. Мы можем связать эту информацию с личной информацией, которую мы
собрали о вас, и использовать ее в целях, описанных в настоящей Политике
конфиденциальности, и мы можем предоставлять вам целевую маркетинговую
информацию и информацию о продукте на основе ваших данных об использовании.<br>
<br>
Данные третьих лиц.&nbsp;Мы можем получать вашу личную информацию от сторонних
поставщиков или партнеров. Если вы подключаетесь к учетным записям Сервисов и
Веб-сайтов на сторонних сайтах социальных сетей, мы можем получать информацию о
ваших учетных записях в социальных сетях, например, ваше имя, имя пользователя
или отображаемое имя, общедоступный профиль и адрес электронной почты. Мы можем
объединять предоставленную вами информацию с автоматически собираемыми данными
и с данными, полученными от третьих лиц. Некоторые третьи стороны, в том числе
аналитические компании, рекламодатели и рекламные сети, могут автоматически
собирать информацию о вас через наши веб-сайты, используя файлы cookie,
веб-маяки и идентификаторы устройств, включая личную информацию о вашей
онлайн-активности с течением времени и на разных сайтах, устройствах,
онлайн-каналах и приложениях.<br>
<br>
Изображения, видео и аудиозаписи.&nbsp;Фотографии, видео или аудиозаписи могут
быть собраны Сервисом и Сайтами. Например, если вы посещаете Сервис и
Веб-сайты, это может быть запечатлено на фотографии или видео. Если вы
позвоните в службу поддержки клиентов Сервиса и Веб-сайтов, ваш звонок может
быть записан. Если вы откажетесь предоставить свою личную информацию или
попросите нас удалить ее, мы не сможем продолжать предоставлять или
поддерживать наши продукты или услуги.<br>
<br>
Данные о вашей компании.&nbsp;Название компании, сфера деятельности компании,
веб-сайт компании, должность в компании, дата начала работы в компании, дата
создания компании, физический адрес компании, данные о сотрудниках,
экономические данные и другие показатели, которые пользователь ввёл на Сервисе
и Веб-сайте.</span></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p class=MsoNoSpacing><span lang=RU>Данные о ваших партнерах. </span></p>

<p class=MsoNoSpacing><span lang=RU style=''>Соглашаясь с
условиями данной Политики, вы подтверждаете наличие у Вас аналогичного согласия
со стороны Ваших Партнеров и ограждаете нас от претензий и исков с их Стороны.</span></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p class=MsoNoSpacing><b><span lang=RU>2. Для чего мы используем ваши личные
данные?</span></b></p>

<p class=MsoNoSpacing><span lang=RU>Ваши личные данные используются
исключительно для улучшения вашего опыта использования Сервиса и Веб-сайтов, а
также в качестве средства для предоставления нам следующего:<br>
<br>
Материалов поддержки, запрашиваемой вами информации или данные, основанные на
использовании вами приложения, чтобы помочь вам получить</span></p>

<p class=MsoNoSpacing><span lang=RU>Чтобы держать вас в курсе любых обновлений,
уведомлений или всего, что имеет отношение к использованию вами Сервиса и
Сайтов;</span></p>

<p class=MsoNoSpacing><span lang=RU>Запрашивать у вас обратную связь или
принимать участие в любом проводимом нами исследовании, которое вы сможете
игнорировать или отказаться от участия;</span></p>

<p class=MsoNoSpacing><span lang=RU>Чтобы помочь вам с любой необходимой вам
поддержкой через любой из наших каналов связи;</span></p>

<p class=MsoNoSpacing><span lang=RU>Чтобы помочь нам обнаружить и предотвратить
любую мошенническую или злонамеренную деятельность, а также убедиться, что все
используют наши веб-сайты и услуги справедливо и в соответствии с договором
оферты. </span></p>

<p class=MsoNoSpacing><span lang=RU>Мы можем использовать личные данные,
которые мы собираем о вас и других пользователях наших Веб-сайтов и Сервисов
(независимо от того, получены ли они напрямую или от третьих лиц), для создания
анонимных и сгруппированных аналитических материалов и отчетов, которыми мы
можем поделиться публично.</span></p>

<p class=MsoNoSpacing><b><span lang=RU>3. Как мы передаем и храним ваши данные?</span></b></p>

<p class=MsoNoSpacing><span lang=RU>У нас есть юридические обязательства по
сбору любой личной информации, которую вы предоставляете нам. Мы будем надежно
хранить вашу личную информацию и будем хранить ее до тех пор, пока вы
пользуетесь запрошенной вами услугой.<br>
<br>
Все ваши личные данные хранятся на защищенных серверах PS Cloud и защищены
методами маскирования и шифрования данных. Мы не передаем ваши персональные
данные третьим сторонним поставщикам или любым другим приложениям или услугам.<br>
<br>
Мы будем передавать ваши персональные данные только регулирующим органам,
правоохранительным органам, государственным органам, судам или другим третьим
лицам, если мы считаем необходимым соблюдать применимые законы или нормативные
акты или осуществлять, устанавливать или защищать наши законные права. Там, где
это возможно и уместно, мы будем уведомлять вас об этом типе раскрытия.<br>
<br>
Сервис и Веб-сайты могут предоставлять информацию сторонним поставщикам услуг
или поставщикам, с которыми мы заключили договор на предоставление услуг от
нашего имени. Эти сторонние поставщики услуг или поставщики могут использовать
информацию, которую мы им предоставляем, только в соответствии с инструкциями
настоящей Политики конфиденциальности и использования файлов cookie.</span></p>

<p class=MsoNoSpacing><b><span lang=RU>4. Как долго мы храним ваши личные
данные?</span></b></p>

<p class=MsoNoSpacing><span lang=RU>Время, в течение которого мы храним ваши
персональные данные, зависит от того, какие это данные и есть ли необходимость
их постоянного хранения (например, чтобы предоставить вам запрошенную вами
услугу или соблюдать требования законодательства).<br>
<br>
Обычно ваши личные данные хранятся до тех пор, пока владелец компании не удалит
сотрудника или пока владелец учетной записи не удалит его. Для бесплатных
аккаунтов мы имеем право удалить вашу информацию, если она не использовалась в
течение 4 месяцев.</span></p>

<p class=MsoNoSpacing><b><span lang=RU>5. Ваши права</span></b></p>

<p class=MsoNoSpacing><span lang=RU>По вопросам отмены подписки на рассылку и
другим вопросам, связанным с работой Сервиса и Веб-сайтов, а также с другими
вопросами, предложениями, вы можете обращаться по электронной почте<span
style=''> support@danametrics.ai.</span><br>
<br>
Вы также имеете право знать, какие персональные данные о вас мы храним, а также
проверять их правильность и актуальность. Вы можете запросить копию ваших
личных данных или попросить нас ограничить обработку ваших личных данных или
удалить их.<br>
<br>
Если вы недовольны тем, как мы обрабатываем ваши личные данные, сообщите нам об
этом, отправив электронное письмо по адресу <span style=''><a
href="mailto:support@danametrics.ai">support@danametrics.ai</a>.</span> Мы
рассмотрим вашу жалобу и свяжемся с вами в разумные сроки. Вы также можете
подать жалобу в местный орган защиты данных. Они смогут посоветовать вам, как
подать жалобу.</span></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p style='margin:0cm;text-align:justify;text-indent:1.0cm'><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Если Вы не согласны с
условиями Политики, то Вы должны немедленно прекратить любое использование
Сайта.</span></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p class=MsoNoSpacing><b><span lang=RU>6. Что такое cookie и как мы их
используем?</span></b></p>

<p class=MsoNoSpacing><span lang=RU>Файлы cookie - это небольшие текстовые
файлы, которые веб-сайты сохраняют на вашем компьютере. Файлы cookie,
используемые Сервисом и Веб-сайтами, не содержат никакой вашей личной
информации, и мы не можем использовать их, чтобы выяснить, кто вы. Файл cookie
часто содержит случайно сгенерированный номер, который хранится на вашем
устройстве. Многие cookie автоматически удаляются после того, как вы закончите
пользоваться Сервисом и Сайтами.<br>
<br>
Когда вы заходите на наш сайт, ваш компьютер автоматически получает файл
cookie. Файлы cookie - это устройства, которые идентифицируют ваш компьютер на
нашем сервере и персонализируют сайт для вашего будущего использования. Файлы
cookie регистрируют только те области нашего сайта, которые посещал компьютер.
Файл cookie не предоставляет нам никакой личной информации. Поэтому, если вы не
предоставили нам какую-либо личную информацию, вы все равно можете
просматривать наш сайт анонимно. Если вы не хотите куки, вы можете настроить
свой браузер, чтобы запретить его. Файл cookie необходим для входа в Сервис и
на Веб-сайты, а также для нашего безопасного хранения вашего логина для вашего
сеанса.<br>
<br>
Улучшение опыта пользователей<br>
Сервис и веб-сайты работают над тем, чтобы его пользователи имели наилучшие
возможности. Это означает, что пользователю не нужно повторять некоторые
действия без необходимости. Например, если вы увеличиваете размер текста, когда
находитесь на Сервисе и Веб-сайтах, может быть установлен файл cookie, который
запоминает предпочитаемый вами размер текста, чтобы при переходе на другую
страницу сайта вам не приходилось менять ваши настройки снова.<br>
<br>
Измерение эффективности сайтов<br>
Мы также можем установить cookie на компьютер пользователя, чтобы отслеживать
количество посетителей. Никакая личная информация, такая как ваше имя или
адрес, не собирается в рамках этого процесса.<br>
<br>
Поддержка обмена информацией между пользователями через социальные сети.<br>
Обмен является ключевой частью того, как люди получают и используют информацию.
Социальные сети являются популярным способом сделать это, чтобы люди могли
делиться информацией о Сервисе и Веб-сайтах таким образом. Владельцы этих
социальных сетей могут размещать файлы cookie на устройствах пользователей, и
использование этих файлов cookie часто объясняется в их собственной политике
использования файлов cookie. Администратор не контролирует политику
использования файлов cookie этих компаний.</span></p>

<p class=MsoNoSpacing><b><span lang=RU>7. Google Analytics</span></b></p>

<p class=MsoNoSpacing><span lang=RU>Служба и веб-сайты используют Google
Analytics, службу веб-аналитики, разработанную компанией Google, Inc.
(«Google»). Программное обеспечение Google Analytics использует файлы cookie
для анализа поведения пользователей. Все данные (т. е. Ваша деятельность в
Службе и на веб-сайтах) собираются с помощью файлов cookie и хранятся на
серверах Google в США.<br>
<br>
Google, в свою очередь, использует эти данные для оценки поведения
пользователей в целом, для составления отчетов для руководителей сайтов, а
также для предоставления данных службам, связанным с активностью веб-сайта и
использованием Интернета. Google может передавать различные данные третьим
лицам, если это требуется по закону или если третьи лица обрабатывают
информацию от имени Google.<br>
<br>
Google никоим образом не будет связывать ваш IP-адрес с любыми другими данными,
хранящимися в Google. Если вы не хотите предоставлять Google данные для
просмотра, вы можете заблокировать использование файлов cookie через настройки
браузера. Дополнительная информация об отказе от аналитики Google:&nbsp;<a
href="https://www.google.com/intl/en/policies/privacy/"><span style='color:
windowtext;text-decoration:none'>https://www.google.com/intl/en/policies/privacy/</span></a>,
и&nbsp;<a href="http://www.google.com/analytics/terms/us.html"><span
style='color:windowtext;text-decoration:none'>http://www.google.com/analytics/terms/us.html</span></a>.<br>
<br>
Google Analytics объясняется по следующей ссылке&nbsp;<a
href="https://www.google.com/analytics/"><span style='color:windowtext;
text-decoration:none'>https://www.google.com/analytics/</span></a>.</span></p>

<p class=MsoNoSpacing><b><span lang=RU>8. Что такое IP-адрес?</span></b></p>

<p class=MsoNoSpacing><span lang=RU>Если вы подключены к Интернету, у вас будет
IP-адрес. Адрес будет автоматически собран и зарегистрирован как часть
подключения вашего компьютера к нашему веб-серверу и может использоваться для
определения общего количества посещений каждой части сайта. Он также
используется для определения приблизительного местоположения страны и помогает
в выявлении потенциальной вредоносной активности.</span></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

<p class=MsoNoSpacing><b><span lang=RU style='color:red'>9. Политика в
отношении пользовательских данных служб Google API</span></b><span lang=RU
style='color:red'> </span></p>

<p class=MsoNoSpacing><a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="blank"><span lang=RU style='color:red'>https://developers.google.com/terms/api-services-user-data-</span><span
lang=EN-US style='color:red'>p</span><span lang=RU style='color:red'>olicy#additional_requirements_for_specific_api_scopes</span></a></p>

<p class=MsoNoSpacing><span style='font-size:12.0pt;font-family:Roboto;
color:#202124;background:white'>&nbsp;</span><span lang=RU><br>
<br>
</span></p>

<p class=MsoNoSpacing><b><span lang=RU>10. Кто мы и как с нами связаться?</span></b></p>

<p class=MsoNoSpacing><span lang=RU>Когда мы ссылаемся на «мы», это относится к
Администратору и веб-сайту&nbsp;<a href="https://app.danametrics.ai/"><span
style='color:windowtext;text-decoration:none'>https://app.danametrics.ai/</span></a>,
Danametrics.</span></p>

<p class=MsoNoSpacing><span lang=RU><br>
<br>
</span></p>

<p class=MsoNoSpacing><span lang=RU>&nbsp;</span></p>

</div>
`,
        }}
      ></div>
    </CommonLayout>
    <div className="flex-grow-0">
      <Footer />
    </div>
  </div>
);
