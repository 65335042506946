import { AuthLayout } from "shared/ui";
import { LigthRoutes } from "shared/types";
import { AuthGuard, AgencyAdminLogin } from "entities/auth/ui";
import { Login, ResetPassword, AnswerResetPassword } from "pages/auth";
import { Registration, RegistrationSuccess } from "pages/auth";
import { AppDescription } from "pages/appDescription";
import { PrivacyPolicy } from "pages/privacyPolicy";

const AuthRoutes: LigthRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <AuthLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "/auth",
          element: <Login loginRole="user" />,
        },
        {
          path: "/auth/superadmin",
          element: <Login loginRole="superadmin" />,
        },
        {
          path: "/register",
          element: <Registration />,
        },
        {
          path: "/register-success",
          element: <RegistrationSuccess />,
        },
        {
          path: "/agency-director-login/:token",
          element: <AgencyAdminLogin loginRole="agency-director" />,
        },
        {
          path: "/agency-user-login/:token",
          element: <AgencyAdminLogin loginRole="agency-user" />,
        },
        {
          path: "/system-admin-login/:token",
          element: <AgencyAdminLogin loginRole="system-admin" />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          path: "/reset-password/:token",
          element: <AnswerResetPassword />,
        },
        {
          path: "/app-description",
          element: <AppDescription />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
      ],
    },
  ],
};

export default AuthRoutes;
